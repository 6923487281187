body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.15);
  }
}

@font-face {
  font-family: 'Noticia Text';
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/builder_fonts/noticiatext_regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Noticia Text';
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/builder_fonts/noticiatext_bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Righteous';
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/builder_fonts/righteous_regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next Condensed';
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/builder_fonts/avenir_next_condensed_demibold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Barlow Condensed ExtraBold';
  font-weight: 800;
  font-style: normal;
  line-height: normal;
  src:
    url('../src/assets/builder_fonts/barlowcondensed_extrabold.ttf') format('truetype');
  font-display: swap;
}

.collisionEffect {
  display: inline-block;
  position: absolute;

}

.collisionEffect::after,
.collisionEffect::before {
  content: '';
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  animation: animloader 0.4s ease;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes highlightDots {
  0% {
    background-color: #BA3C4A;
    filter: none;
  }

  25% {
    background-color: #FFF4A4;
    filter: drop-shadow(0px 0px 10px #FFF4A4) drop-shadow(0px 0px 4px #FFF4A4);
  }

  50% {
    background-color: #BA3C4A;
    filter: none;
  }

  100% {
    background-color: #BA3C4A;
    filter: none;
  }
}
